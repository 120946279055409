.pass-list{
    margin: 0px;
    padding-left: 20px;
}
.checkboxStyle{
    padding:0px 0.556vw 0px 4px !important;
    color:#1E293B !important;
    font-size:1.438rem !important;
}
.MuiSvgIcon-root{
    width: 34px;
    height: 34px;
}
.category-1{
    display: flex !important;
    justify-content: center !important;
    margin-top: 0.972vw;
    margin-bottom: 2.431vw;
}
.category-1-event{
  margin-top: 0.972vh;
}
.category-1-radio-event{
  row-gap: 1vw !important;
}
.category-2{
    display: flex;
    justify-content: center;
    margin-top: 2.431vw;
    margin-bottom: 1.319vw;
}

.category-2-2{
    display: flex;
    justify-content: space-between;
}

.category-2-1 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
}

.category-2-1 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
.category-2-1-new {
  display: flex;
  justify-content: center;
}

.category-2-1-box {
  padding-bottom: 1.111vw;
  box-sizing: border-box; 
  white-space: nowrap; 
}

.label-style .MuiFormControlLabel-label{
  
  font-weight: 500 !important;
  font-size: 1.111vw !important;
  color: #712220 !important;
}

.label-style{
  margin-left: 0px !important;
  margin-right: 1.111vw !important;
  color: #712220 !important;
  
}

.label-style-event .MuiFormControlLabel-label{
  
  font-weight: 500 !important;
  font-size: 1.111vw !important;
  color: #712220 !important;
}

.label-style-event{
  margin-left: 0px !important;
  margin-right: 0px !important;
  color: #712220 !important;
  
}

.radioBox1 {
  width: 2.09vw;
  height: 2.09vw;
  display: inline-block;
  border: 2px solid #712220;
  border-radius: 50%;
  position: relative;
}

.radioBox1.checked::after {
  content: '';
  width: 1.25vw;
  height: 1.25vw;
  background-color: #712220;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radioBox1-event {
  width: 2.09vw;
  height: 2.09vw;
  display: inline-block;
  border: 2px solid #712220;
  border-radius: 50%;
  position: relative;
}

.radioBox1-event.checked::after {
  content: '';
  width: 1.25vw;
  height: 1.25vw;
  background-color: #712220;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.category-2-1-other{
    display: flex;
    justify-content: center;
}

.text-area-style-catalogue{
  width: 32.5vw !important;
  color:#712220 !important;
  font-size: 1.111vw !important;
  
  border-radius: 5px;
}
.MuiInputBase-input::placeholder{
    color: #712220 !important;
    opacity: 1 !important;
    font-weight: 400 !important;
    font-size: 1.111vw !important;
}
.category-3{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 3.889vw;
    margin-bottom: 1.667vw;
}
.category-3-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.667vw;
  grid-column-gap: 6.25vw;
}

.category-3-1-formlabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.category-3-1-formlabel .MuiFormControlLabel-root {
  flex: 1;
}

.category-3-1-formlabel .MuiSelect-root {
  flex: 1;
}

.category-3-1-2{
  display: flex;
  gap: 88px;
}


.category-3-2{
    display: flex;
    justify-content: space-between;
    margin-top: 6px; 
}
.category-3-2-1{
    display: flex;
    flex-direction: column;
}
.category-3-3{
    display: flex;
    justify-content: space-between;
    margin-top: 41px;
}
.category-3-3-1 {
  display: flex;
  flex-wrap: wrap;
}

.category-item {
  flex: 1 1 calc(33.33% - 10px);
  margin-bottom: 2.222vw;
  box-sizing: border-box;
}
.category-4{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 22px;
    margin-top: 9px;
}
.category-5-btn-clicked {
    background-color: #712220 !important ; 
    font-weight: 500 !important;
    
    color: #fff !important;
    min-width: 6.25vw !important;
    text-transform: none !important ;
    border-radius: 4px !important;
    min-height: 2.708vw !important;
    font-size: 1.111vw !important;
    padding: 0 !important;
}

.category-5-container{
    display: flex;
    flex-direction: column;
}
.start-icon-size{
  width: 1.319vw;
  height: 1.251vw;
}
.category-5{
    width: 23.194vw;
}
.category-5-1{
    margin-top: 3.889vw;
    margin-bottom: 17.5px;
}
.category-5-2{
    display: flex;
    justify-content: space-between;
    margin-top: 1.7vw;
    align-items: center;
    padding-right: 5vw;

}
.category-5-btn-container{
    display: flex;
    gap: 0.347vw;
}
.category-5-btn{
    color: #712220 !important;
    border: 1px solid #712220 !important;
    min-width: 6.25vw !important;
    border-radius: 4px !important;
    text-transform: none !important ;
    font-size: 1.111vw !important;
    font-weight: 500 !important;
    min-height: 2.708vw !important;
    padding: 0 !important;
}
.font {
  font-family: "Museo Sans", sans-serif !important; 
}
.category-5-btn-time{
  color: #712220 !important;
  border: 1px solid #712220 !important;
  border-radius: 4px !important;
  text-transform: none !important ;
  font-size: 1.111vw !important;
  font-weight: 400 !important;
  
  min-height: 2.708vw !important;
  width: 12.847vw !important;
}
.category-3-4-5-container{
    display: flex;
    gap: 4.722vw;
    justify-content: center
}
.agree-to-typo{
  
  font-weight: 400 !important;
  font-size: 0.972vw !important; 
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover{
  background-color: #7122201A !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
   border: 1px solid #712220 !important;
   margin-top: 3px !important;
}

.menu-item-establishment{
  font-size: 0.972vw !important;
  
  color: #712220 !important;
}

/* @media (max-width: 1200px) {
    .category-3-4-5-container {
        flex-direction: column;
        justify-content: center;
    }
    .category-5-container-1{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .category-3-1{
        justify-content: space-between;
    }
} */

.submit-btn{
    background-color: #712220 !important;
    color: #fff !important;
    
    text-transform: none !important;
    width: 13.889vw !important;
    height: 3.889vw !important;
    margin-top: 2.604vw !important;
    font-size: 1.25vw !important;
    font-weight: 600 !important;
    border-radius: 8px !important;
}

.set-hours-btn{
  
    background-color: #712220 !important;
    color: #fff !important;
    padding: 2vh 2.5vw;
    text-transform: none !important;
    /* width: 13.889vw !important; */
    /* height: 3.889vw !important; */
    /* margin-top: 2.604vw !important; */
    font-size: 1.25vw !important;
    font-weight: 600 !important;
    border-radius: 8px !important;

}
.submit-btn-popup{
  background-color: #712220 !important;
  color: #fff !important;
  
  text-transform: none !important;
  width: 11.528vw !important;
  margin-top: 2.222vw !important;
  height: 3.889vw !important;
  font-size: 1.111vw !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  margin-right: 4.167vw !important;
  margin-bottom: 4.167vw !important;
}
.cancel-btn{
  color: #712220 !important;
  text-transform: none !important;
  width: 12.292vw !important;
  
  height: 3.889vw !important;
  margin-top: 2.222vw !important;
  font-size: 1.111vw !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  margin-bottom: 4.167vw !important;
}
.alert-submission{
  background-color: rgb(46, 125, 50) !important;
  color: #fff !important;
}
.submit-btn:disabled {
    opacity: 0.8 !important;
    background-color: #700806 !important;
    cursor: not-allowed;
  }
.MuiSelect-icon {
    color: #DA9A22 !important;
    top: calc(50% - 14px) !important;
}
.mainContainerCustom {
    background-image: url("../assets/image_sign-up-background.svg") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex !important;
    justify-content: center !important;
  }
  
  .formContainerCustom {
    background-color: #fff;
    width:84.45%;
    border-radius: 8px;
    padding-top: 84px;
    padding-bottom: 58px;
    margin-top: 11.667vw;
    margin-bottom: 87px;
  }
  
  .iconContainer {
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    gap: 7.14px;
  }
  
  .iconGrid {
    margin-bottom: 35.18px;
    justify-content: space-between;
  }
  
  .bar-hopping-logo-registration {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 59px;
    gap: 15px;
    font-weight: 250;
  }
  .logo-width{
    width: 39.1%;
  }
  
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .btnStyleCustom {
    background-color: #70211F !important;
    width: 127px !important;
    border-radius: 8px !important;
    height: 44px !important;
    font-weight: 600 !important;
    
    font-size: 1.25vw !important;
    color: #fff !important;
    text-transform: none !important;
  }
  
  .authorized {
    margin-left: 20px;
    display: flex;
    align-items: flex-start;
  }
  
  .passValidation {
    font-size: 11px !important;
    margin-top: 7px !important;
    font-style: italic !important;
    
  }
  
  .grid-container-custom{
    justify-content: space-between;
}

@media (max-width: 1000px) {
    .grid-container-custom {
      flex-direction: column;
      align-items: center; 
      padding: 0px; 
      gap: 20px;
    }
  }

.agreeWrapper {
    background-color: #F3F0D7;
    display: flex;
    width: 46.4%;
    margin: 40px auto !important;
    align-items: center;
    padding: 14px 53px 14px 53px;
    justify-content: space-between;
  }
  
  .secondaryColorTerms {
    color: #9C0E2E !important;
    text-decoration: none !important;
    text-transform: none !important;
    margin-top: -2px !important;
    margin-left: -8px !important;
    
    font-weight: 600 !important;
    font-size: 0.972vw !important;
    background-color: transparent !important;
  }
  
  .txtFieldStyle {
    display: flex;
    align-items: center;
    gap: 7px;
    width: 94.5%;
    
  }
  
  .iconTextStyle {
    font-size: 7.13px;
    color: #712220 !important;
    margin-top: 7.14px;
    font-style: italic;
  }
  
  .grid2Heading {
    color: #732120;
    font-weight: 600;
    margin-bottom: 2.222vw;
    align-items: center;
    justify-items: center;
    
    font-size: 1.11vw;
  }
  
  .textStyle {
    
  }
  
  .textStyle .MuiOutlinedInput-input{
    font-weight: 400 ;
    font-size: 16px;
    font-family: "Museo Sans", sans-serif !important; 
}

.MuiOutlinedInput-input{
  font-family: "Museo Sans", sans-serif !important; 
}

  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
    
  }

  .css-1arijsr-MuiSnackbar-root{
    top:6.944vw !important;
  }
  
  .authorized-typography{
    
  }

  .muiGrid-item-1{
    padding: 0px 35px 0px 86px;
  }
  .muiGrid-item-2{
      padding: 0px 86px 0px 35px;
  }

  @media screen and (max-width: 1120px) {
    .muiGrid-item-1{
        min-width: 100%;
        padding: 0px 86px 0px 86px;
    }
    .muiGrid-item-2{
        min-width: 100%;
        margin-top: 20px !important;
        padding: 0px 86px 0px 86px;
    }
    .agreeWrapper{
      flex-direction: column;
    }
    .btnStyleCustom{
      margin-top: 10px !important;
    }
}

.textfield-error-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.textfield-error-wrapper-website{
  display: flex;
  flex-direction: column;
  width: 92.3%;
}

.invalid-error-backend-customform{
  margin-top: -4px;
  font-size: 12px;
  color: #f94b4b;
  text-align: right;   
}

.invalid-error-backend-customform-zip{
  margin-top: -4px;
  font-size: 12px;
  color: #f94b4b;
  text-align: right;
  margin-right: 22px;
}

input.adressstyle {
  border: 1px solid #712220;
  font-size: 16px;
  border-radius: 4px;
  padding: 9.5px 12px;
}

input.adressstyle::placeholder {
  color: #712220;
  font-size: 16px;
  opacity: 1;
}

input.adressstyle:focus {
  border: 1px solid #712220; 
  outline: none;
}

.popup-heading-customform{
  font-size: 1.667vw !important;
  
  font-weight: 600 !important;
  padding: 4.167vw 4.167vw 2.222vw 4.167vw !important;
}

.popup-column-customform{
  color: #777777 !important;
  
  font-weight: 500 !important;
  font-size: 0.972vw !important;
}
.popup-hours .MuiPaper-rounded{
  max-width: 54.861vw !important;
  width: 54.861vw !important;
}

.popup-hours-content{
  padding: 0px 4.167vw 0px 4.167vw !important;
}
.popup-closed-typo{
  font-size: 1.111vw !important;
  font-weight: 500 !important;
  margin-top: 1.111vw !important;
  color: #181818 !important;
  height: 3.125vw !important;
  display: flex;
  align-items: center;
  
}
.input-time-field {
  height: 3.125vw !important;
  margin-top: 1.111vw !important;
}
.margin-opening-days{
  margin-bottom: 1.181vw !important;
}

.checkbox-days{
  padding: 0.625vw 0.625vw 0.625vw 0.625vw !important;
}
.checkbox-days-remember{
  padding: 0.625vw 0.225vw 0.625vw 0.625vw !important;
}

.checkbox-days  .MuiSvgIcon-root{
  width: 1.667vw !important;
  height: 1.667vw !important;
  border-radius: 6px;
  border-bottom-left-radius:8 !important;
  font-family: "Museo Sans", sans-serif !important;
}
.checkbox-days .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
  color: #64748B !important;
}

.action-popup .css-knqc4i-MuiDialogActions-root{
  padding: 0 !important;
}

.checkbox-dropdown{
  margin-bottom: 1.354vw !important;
  width: 8.125vw !important;
}

.checkbox-dropdown-label {
  
  font-weight: 400 !important;
  font-size: 1.111vw !important;
  color: #181818 !important;
}

.popup-closed-typo-2{
  height: 3.125vw !important;
  margin-top: 1.111vw !important;
}

.dropDownStyle{
  height: 40px !important;
  color: #712220 !important;
  font-size:16px !important;
  margin-top:8px !important;
}

.bg {
  display: flex;
  flex-direction: column;
  height: 100vh
}

.backContainer {
  padding-left: 25px;
} 

.mainContainer {
  display: flex;
  flex-direction: column;
  padding-top: 150px;

}

.otpContainer {
  display: flex;
  justify-content: center;
  gap: "10px";
  margin-top: "2wh";
}

.otpInput {
  width: "4vw" !important;
  height: "4vh" !important;
  font-size: "1.5vw"!important;
  text-align: "center"!important;
  border: "1px solid #ccc" !important;
  border-radius: "5px" !important;
  margin-right: "0.5vw";
}

.backButton {
  
    border-radius: '0vw';
    background-color: 'white';
    width: 'auto';
    font-weight: 400;
    font-size: '1.25vw';
    font-family: "Museo Sans", sans-serif;
    height: '3.889vw';
    color: 'black';
    cursor: 'pointer';
    text-transform: 'none';
  
}