.termsAndCondContainer{
    margin-top: 5.1%;
    max-width: 1080;
    margin-bottom: 311px;
}
.termsAndCondContainerBusiness{
    margin-top: 8.4%;
    padding: 0 8.4%;
    max-width: 1080;
    margin-bottom: 311px;
}
.back-btn-typo-terms{
    text-transform: none !important;
}
.btn-container-terms{
    padding: 0px !important;
    margin-bottom: 15px !important;
    background-color: transparent !important;
    min-width: fit-content !important; 
}
.mainContainerTerms{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5.64%;
}
.termsAndCondContainer{
    margin-top: 5.1%;
    max-width: 1080;
    margin-bottom: 311px;
    padding: 0 8.4%;
}