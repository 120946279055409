.formInputStyle .MuiOutlinedInput-input{
    font-weight: 400 ;
    font-size: 16px;
    font-family: "Museo Sans", sans-serif !important; 
}

.formInputStyle .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    color: #732120 !important;  
    border-radius: 5px !important;
    border: 1px solid #732120 !important;
}
  
  .formInputStyle .MuiOutlinedInput-notchedOutline{
    color: #732120 !important;  
    border-radius: 5px !important;
    border: 1px solid #732120 !important;
  }

.formInputStyleEvents .MuiOutlinedInput-input{
    font-weight: 500 ;
    font-size: 1.111vw;
}
.formInputStyleEvents .MuiInputBase-input{
    border-radius: 5px ;
    border: 1px solid #732120;
    opacity: 1;
    font-family: "Museo Sans", sans-serif !important;
}
.formInputStyleEvents{
    margin-top: 1.111vw !important;
    margin-bottom: 0.556vw !important;
}

.MuiButton-contained.Mui-disabled{
    opacity: 0.8 !important;
}

.douche-style{
    font-size: 12px;
    margin-left: 26px;
    font-style: italic;
  
}
.terms-conds-btn{
    text-transform: none !important;
    background-color: transparent !important;
}

.muiGrid-item-1{
    padding: 0px 35px 0px 86px;
}
.muiGrid-item-2{
    padding: 0px 86px 0px 35px;
}
.icongridStyle {
    margin-bottom: 3.819vw;
    justify-content: space-between;
}
.iconContainerSignup {
    display: flex;
    justify-content:flex-end !important;
    align-items: center;
}
.icon-img{
    cursor: pointer;
}
.star-icon-signup{
    width: 13px !important;
}
.passValidationSignup {
    font-size: 0.764vw !important;
    margin-top: 7px !important;
    font-style: italic !important;
    margin-left: 1.806vw !important;
  
  }

.acount-upload-img-wrapper{
    margin-bottom: 59px;
    text-align: center;
    margin-left: 80px;
    margin-right: 80px;
}

.upload-btn-account{
    background-color: #732120;
    color: #FFF;
    border-radius: 4px;
    padding: 4px 8px 4px 8px;
    margin-top: 10px;
}
.textfield-error-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.invalid-error-backend{
    margin-top: -8px;
    font-size: 12px;
    color: #f94b4b;
    text-align: right;   
}
.upload-profile-patron{
    font-size: 16px !important;
    font-weight:600 !important;
    color: #000000 !important;
}

@media screen and (max-width: 1120px) {
    .grid-container-registration{
        display: flex;
        flex-direction: column;
    }
    .muiGrid-item-1{
        min-width: 100%;
        padding: 0px 86px 0px 86px;
    }
    .muiGrid-item-2{
        min-width: 100%;
        margin-top: 20px !important;
        padding: 0px 86px 0px 86px;
    }
}