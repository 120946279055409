
.barHoppinLogo {
    height: 2.73vw;
  }

  .bar-hoppin-logo-btn{
    padding: 0 !important;
  }

  .active-btn-header{
    text-transform: none !important;
  
    color:#DA9A22 !important;
    font-size: 0.972vw !important;
    font-weight: 600 !important;
  }
  .header-2-btn{
    text-transform: none !important;
    color: #fff !important;
  
    font-size: 0.972vw !important;
    font-weight: 400 !important;
  }
  
  .toolBar {
    padding-left: 7.7vw !important;
    padding-right: 8.6vw !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #181818 !important;
    min-height: 5.625vw !important;
  }
  
  .loginButton {
    background-color: #712220 !important;
    border-radius: 8px !important;
    width: 13.20vw !important;
    max-width: 13.20vw !important;
    min-width: 13.20vw !important;
    height: 2.3vw !important;
    font-size: 0.972vw !important;
    color: #fff !important;
    margin-left: 2.34vw !important;
    text-transform: none !important;
  
  }
  
  .typographyStyleFooter1 {
    white-space: nowrap !important;
    color: #fff !important;
    font-size: 0.972vw !important;
    font-weight: 400 !important;
  
  }
  
  .termsCondColor {
    color: #DA9A22 !important;
    text-transform: none !important;
    cursor: pointer;
    font-size:0.972vw !important;
  }

  .footer-2-explore{
    color: #DA9A22 !important;
    font-weight: 600 !important;
    text-transform: none !important;
    font-size:0.972vw !important;
    cursor: pointer !important;
  
  }
  .footer-2-btn{
    text-transform: none !important;
    color: #fff !important;
    font-size:0.972vw !important;
    font-weight: 400 !important;
    cursor: pointer !important;
    margin-top: 15px !important;
  
  }
  .active-btn-footer{
    text-transform: none !important;
  
    color:#DA9A22 !important;
    font-size: 0.972vw !important;
    margin-top: 15px !important;
    font-weight: 600 !important;
  }
  .footer-2-container{
    display: flex;
    padding-left: 20.6vw;
    padding-right: 20.6vw;
    justify-content: space-between;
  }
  .footer-2-container-1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footer-2-address-typo-1{
    margin-top: 18px;
    margin-bottom: 28px;
    padding-left: 9px;
    padding-right: 9px;
  }
  .footer-2-address-typo-email{
    padding-left: 9px;
    padding-right: 9px;   
  }
  .footer-2-toolBar{
    padding-top: 46.5 !important;
    padding-bottom: 46.5 !important;
    flex-direction: column !important;
    align-items:flex-start !important;
    justify-content: center !important;
    background-color: #181818 !important;
  }
  .copyright-typo{
    padding-left: 131px !important;
    margin-top: 7vw;
  }
  .barHoppinLogoStyle-footer{
    width: 14.6vw !important;
  }

  /* AboutUs Css */
  .about-heading-container{
    padding-top: 13.611vw;
    padding-bottom: 15.556vw;
    padding-left: 12.5vw;
    width: 29.73%;
  }
  .about-heading{
    font-size: 3.472vw !important; 
    font-weight: 700 !important;
    color: #FFFFFF;
    text-transform: uppercase !important;
    line-height: normal !important;
  
  }
  .heading-description{
    font-size: 1.667vw !important;
    color: #FFFFFF;
    font-weight: 400 !important;
    line-height: normal !important;
  
  }
  .aboutus-card-img{
    width: 250px;
    height: 17.4vw;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
  }
  .about-img-des-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #181818 !important;
  }
  .about-description{
    margin-top: 8.6vw;
    margin-bottom: 9.8vw;
    font-size: 1.25vw;
    color: #FFFFFF;  
  }
  .about-description2{
    font-weight: 400 !important;
    font-family: "Museo Sans", sans-serif !important;
  }
  .img-container{
    margin-top: -8.7%;
    display: flex;
    justify-content: space-between;
  }
  .item-img-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .typo-heading-aboutus{
    color: #FFFFFF;
    font-weight: 700 !important;
    font-size: 1.7vw !important;
  }
  .typo-body-aboutus{
    color: #FFFFFF;
    font-size: 1.2vw !important;
  
  }
  .card-content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.222vw 0px 0px 0px !important;
  }

  /* Travel Article Css */
  .blog-bg-container-1{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .blog-heading-container{
    padding-top: 11.042vw;
    padding-bottom: 4.236vw;
    padding-left: 12.5vw;
    width: 28.958vw;
  }
  .extra-spacing{
    padding-bottom: 10.5vw !important
  }
  .slider-btns{
    display: flex;
    height:1.667vw;
    gap:20px !important;
    cursor: pointer;
    padding-top: 7.556vw;
  }
  .blog-category{
    background-color: #C33836;
    width: fit-content;
    color:#FFFFFF;
    padding: 0.56vw 0.84vw;
    font-size: 0.87vw !important;
  }
  .blog-heading{
    font-size: 2.5vw !important; 
    font-weight: 700 !important;
    color: #FFFFFF;
    width: 28.958vw;
    height: 7.917vw;
    letter-spacing: 0em !important;
    text-transform: uppercase !important;
  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  .blog-description{
    font-size: 1.39vw !important;
    color: #FFFFFF;
  
  }
  .blog-content{
    display: flex;
    color:#DA9A22 !important;
    align-items: center;
    justify-content: space-between;
  }
  .blog-content-1{
    display: flex;
  }
  .blog-content-font{
    font-size: 0.84vw !important;
  
  }
  .blog-date{
    margin-left: 1.12vw !important;
  }
  .blog-dinner-night-img-container{
    padding-top: 13.6vw;
    padding-right: 20.9vw;
  }
  .blog-dinner-night-img{
    width: 25.5vw;
  }
  .blog-container{
    display: flex;
    padding: 4.514vw 0vw;
    gap:107px;
  }
  .blog-card-img{
    height: 20.833vw;
    object-fit: cover;
    overflow: hidden;
  }
  .blogs-wrapper{
    /* width: 55.944vw; */
  }
  .blog-container-3{
    display: flex;
    flex-direction: column;
  }
  .blog-card-content-container{
    display: flex;
    flex-direction: column;
  }
  .blog-author-container-2{
    color:#000000;
  }
  .blog-date-container-2{
    color:#666666;
    margin-left: 1.12vw !important;
  }
  .blog-read-more-container-2{
    color:#9C0E2E;
  
    font-weight: 600 !important;
    cursor: pointer !important;
  }
  .blog-read-more{
    cursor: pointer !important;
  }
  .typo-heading-blog{
    font-weight: 700 !important;
  
    font-size: 1.67vw !important;
  }
  
  .typo-description-blog{
  
    font-size: 0.972vw !important;
  }

  .typo-description-blog-selected{
  
    font-size: 1.12vw !important;
  }
  .blog-search{
    border: 2px solid #000000;
    max-width: 15.28vw;
    border-radius: 52px;
    display: flex;
  }
  .search-placeholder .MuiInputBase-input::placeholder{
    color:#646464 !important;
  }
  .blog-container-3-heading{
    color:#C33836;
    font-weight: 700 !important;
    background-color: #E8E8E8;
    padding: 0.84vw 0px 0.77vw 1.12vw;
    margin-top: 1.67vw !important;
    margin-bottom: 0.7vw !important;
    width: 17.1vw;
  
    font-size: 1.12vw !important;
  }
  .blog-container-3-categories{
    margin-left: 0.903vw;
    display: flex;
    flex-direction: column;
  }
  .blog-container-3-category{
    font-size: 0.972vw !important;
  
  }
  .selected-category{
    color: #712220;
  }
  .blog-container-3-cities{
    margin-left: 0.903vw;
    display: flex;
    flex-direction: column;
  }
  .blog-img-content{
    display: flex;
    justify-content: space-between;
    padding: 6px 12px 6px 12px;
    align-items: center;
    background-color: #9C0E2E !important;
  }
  .prev-next-btn{
    padding: 0 !important;
    justify-content: flex-start !important;
    min-width: fit-content !important;
  }
  .category-list-btn{
    padding: 0 !important;
    justify-content: flex-start !important;
    text-transform: capitalize !important;
    margin-bottom: 11px !important;
    font-size: 0.972vw !important;
  }
  .blog-category-container-2{
    color:#FFFFFF;
  }
  .blog-share-container-2{
    color:#FFFFFF;
    margin-left: 4px !important;
  }
  .btn-share-readmore-container{
    color:#732120 !important;
    margin-left: 4px !important;
    font-size: 1vw !important;
    text-transform: none !important;
  }
  .share-btn-icon-blog{
    width: 4.653vw;
  }
  .share-btn-icon-article{
    width: 4.653vw;
    margin-left: 10px;
  }
  .logout-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logout-username{
    color:#fff !important;
  
    font-size: 0.972vw !important;
  }
  .user-profile-icon{
    height: 2.292vw;
  }
  .btn-container-travel{
    padding: 0px 0px 0px  0px!important;
    margin-right: 1.042vw;
    background-color: transparent !important;
    min-width: fit-content !important; 
  }
  .blog-description p{
    margin: 0 !important;
  }
