.landing-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: "6.32vw" !important; 
}
.landing-img-width{
  width:100vw;
}
.image-1-landing{
  height: 48.333vw;
  margin-top: 6.319vw;
}
.image-2-landing{
  height: 36.389vw;
}
.image-label-landing-text{
  margin-top: 10.069vw;
  margin-bottom: 4.375vw;
}
.image-label-landing{
  font-weight: 700 !important;
  font-size: 2.5vw !important;

}
.image-3-landing{
  width: 42.014vw;
  height: 10.347vw;
  margin-top: 13.611vw;
  margin-bottom: 5.651vw;
  text-align: center;
}
.image-9-landing{
  width: 39.4vw;
  height: 6.389vw;
  margin-top: 13.889vw;
  margin-bottom: 2.014vw;
  text-align: center;
}
.image-3-landing-text1{
  font-size: 0.972vw !important;
  font-weight: 700 !important;

  color:#C33836;
}
.image-3-landing-text2-box{
  margin-top: 2.639vw;
}
.image-9-landing-text2-box{
  margin-top: 2.083vw;
}
.image-3-landing-text2{

  font-size: 2.2vw !important;
  font-weight: 700 !important;
}
.image-5-landing{
  margin-top: 8.472vw;
  margin-bottom: 7.708vw;
}
.image-7-landing{
  margin-top: 10.625vw;
  margin-bottom: 10.486vw;
}
.image-7-landing-img{
  height: 39.861vw;
}
.image-8-landing{
  height: 105.833vw;
}
.image-11-landing{
  margin-top: 12.708vw;
  margin-bottom: 13.056vw;
}
.image-11-landing-img{
  height: 47.639vw;
}


/* Header CSS */
.appbar-business-landing{
  background-color: transparent !important;
  box-shadow: none !important;
}
.toolbar-business-landing{
  padding-left: 7.7vw !important;
  padding-right: 8.6vw !important;
  align-items: center !important;
  justify-content: center !important;
  min-height: 6.32vw !important;
  background-color: #fff;
}
.bar-hooping-logo-landing{
  height: 2.73vw;
}
.landing-header-home{
  color: #712220 !important;
  text-transform: none !important;
  font-size: 0.972vw !important;
  padding: 0 !important;
  font-weight: 600 !important;
}
.landing-business-list{
  color: #181818;
  font-size: 0.972vw !important;
  text-transform: none !important;
  margin: 0 0 0 2.02vw !important;
  font-weight: 400 !important;
}
.buttonsLanding {
  background-color: #712220 !important;
  border-radius: 8px !important;
  font-size: 0.972vw !important;
  font-weight: 600 !important;
  color: #fff !important;
  text-transform: none !important;
  height: 2.8vw !important;
  padding: 0 !important;

}

.loginButtonLanding{
  margin-left: 0.84vw !important;
  width: 6.4vw !important;
}

.getStartedButtonLanding{
  margin-left: 3.61vw !important;
  width: 8.54vw !important;
}



/* Footer CSS */
.termsCondColorLanding{
  color:#9C0E2E;
  text-transform: none !important;
  font-size: 1.111vw !important;
  cursor: pointer !important;

}
.typographyStyleFooterLanding {
  white-space: nowrap !important;
  font-size: 1.111vw !important;
  font-weight: 500 !important;

}

.testimonial-wrapper{
  width: 95.833vw;
  height: 39.861vw;
  background-color: #712220;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.testimonial-content{
  width: 34.722vw;
  align-items: center;
}
.font-family{

}
.testimonial-title{
  font-size: 0.972vw !important;
  font-weight: 700 !important;
  letter-spacing: 0.139vw !important;
  text-align: center;
}
.testimonial-heading{
  font-size: 2.5vw !important;
  font-weight: 700 !important;
  margin-top: 2.083vw !important;
  margin-bottom: 4.722vw !important;
  text-align: center;
}
.testimonial-desc{
  font-size: 2.083vw !important;
  font-weight: 300 !important;
  font-style: italic !important;
  text-align: center;
}
.testimonial-auth{
  font-size: 1.25vw !important;
  font-weight: 400 !important;
  text-align: center;
}
.auth-btn-wrapper{
  display: flex;
  justify-content: space-between;
  margin-top: 5.556vw;
}
.testimonial-btn-img{
  width: 1.383vw;
}
.testimonial-btn-container{
  display: flex !important;
  gap: 30px !important;
}
.testimonial-btn{
  padding: 0 !important;
  min-width: fit-content !important;
}
.font2{
  font-family: "Montserrat", sans-serif !important;
}