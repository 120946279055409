.business-logo-info-container{
    background-color: #000000CC;
    display: flex;
    padding: 2.569vw 0px 2.569vw 14.097vw;
    align-items: center;
    color: #fff;
    gap: 2.083vw;
    margin-top: -13.264vw;
}
.sub-directory-img{
    height: 52.223vw;
    width: 100%;
}
.business-logo{
    height: 8.125vw;
    width: 8.125vw;
    border-radius: 50%;
}
.business-container-name{
    font-size: 1.667vw !important;
    font-weight: 600 !important;
    
}
.business-container-category{
    font-size: 1.25vw !important;
    
}
.business-container-type{
    font-size: 1.25vw !important;
    
}
.star-share-report-container{
    display: flex;
    justify-content: space-between;
    padding: 3.75vw 0px 0px 0px;
}
.star-share-container{
    display: flex;
    gap: 3.403vw;
}
.btn-subdirectory{
    padding: 0 !important;
}
.btn-subdirectory-cal{
    padding: 0 !important;
    min-width: fit-content !important;
}
.box1-box2-container{
    display: flex;
    justify-content: space-between;
    gap: 8.75vw;
}
.box-1-container{
    padding-top: 2.222vw;
    width: 47.917vw;
}
.box-2-container{
    padding-top: 2.917vw;
    width: 19.653vw;
}
.box2-heading{
    font-size: 1.25vw !important;
    font-weight: 600 !important;
    
}
.box1-description-container{
    padding-bottom: 2.778vw;
}
.sub-directory-heading{
    font-size: 1.25vw !important;
    font-weight: 600 !important;
    color: #712220 !important;
    
}
.sub-directory-heading-description{
    font-size: 1.25vw !important;
    overflow-wrap: break-word !important;    
}
.btn-share-moreinfo-container{
    color:#732120 !important;
    margin-left: 0.278vw !important;
    font-size: 1vw !important;
    font-weight: 600 !important;
    text-transform: none !important;
    
}
.btn-share-moreinfo-container-event{
    color:#732120 !important;
    margin-left: 0.278vw !important;
    font-size: 1vw !important;
    font-weight: 600 !important;
    text-transform: none !important;
    
}
.subdirectory-heading-container-1{
    display: flex;
    justify-content: space-between;
    padding-right: 1.389vw;
}
.subdirectory-heading-container-2{
    display: flex;
    justify-content: space-between;
}
.sub-directory-heading-info{
    color: #712220 !important;
    font-size: 0.972vw !important;
    font-weight: 600 !important;
    cursor: pointer;
    
}
.badges-img-container{
    display: flex;
    justify-content: space-between;
    padding-top: 1.667vw;
}
.badges-img-style{
    height: 7.778vw;
}
.divider-sub{
    border-color: #712220 !important;
    margin-bottom: 2.778vw !important;
}
.divider-badges{
    border-color: #70211F4D !important;
    margin-bottom: 2.778vw !important;
    margin-top: 2.778vw !important;
}
.upcomingEventContainer{
    border: 1px solid #712220 !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 12.5vw;
    padding-left: 1.74vw;
    padding-right: 1.74vw;
    border-radius: 8px !important;
}
.itemGridBoxActivity{
    padding-top: 1.667vw;
    padding-bottom: 2.778vw;
}
.upcoming-events-container{
    display: flex;
    align-items: center;
    padding-bottom: 4.861vw;
    padding-top: 2vw;
}
.upcoming-arrow-btn{
    height: 3.194vw;
}
.img-library-subdirectory{
    width: 7.639vw;
    height: 7.778vw;
    border-radius: 10.68px !important;
}
.libraryImageContainerSubDirectory1{
    box-shadow: none !important;
    width: 7.639vw;
    height: 7.778vw;
    border-radius: 10.68px !important;
}
.libraryImageContainerSubDirectory{
    border: 1px solid #712220 !important;
    box-shadow: none !important;
    width: 7.639vw;
    height: 7.778vw;
    border-radius: 10.68px !important;
}
.itemGridBoxSubDirectory{
    padding-top: 1.667vw;
    padding-bottom: 1.667vw;
}
.amenities-img-container{
    padding-top: 1.528vw;
    padding-bottom: 1.528vw;
    width: 44.7vw;
    display: flex;
    flex-wrap: wrap;
    gap: 1.8vw; 
}
  
.icon-img-catalogue {
    height: 2.569vw;
    width: 2.569vw;
}
.icon-container-amenities{
    display: flex;
    text-align: center;
    flex-direction: column;
    gap:0.496vw;
}
.icon-img-catalogue:nth-child(n + 11) {
    margin-top: 2.222vw;
}

.amenities-more-typo{
    font-size: 1.111vw !important;
    font-weight: 500 !important;
    margin-bottom: 0.764vw !important;
    
}
.amenities-more-typo-1{
    font-size: 1.111vw !important;
    font-weight: 500 !important;
    
}
.amenities-more-typo-body{
    font-size: 0.972vw !important;
    font-weight: 500 !important;
    margin-bottom: 0.764vw !important;
    
}
.sub-category-container{
    display: flex;
}
.tab-panel-catalogue{
    padding: 0 !important;
    margin-top: 1.111vw;
}
.tablist-catalogue{
    min-height: 2.014vw !important;
}
.happy-hours-tab{
    border: 1px solid #712220 !important ;
    color: #712220 !important;
}
.hours-view-tab {
    font-weight: 600 !important;
    font-size: 0.972vw !important;
    min-height: 2.014vw !important;
    min-width: fit-content !important;
    padding: 0.451vw 0.694vw 0.451vw 0.694vw !important;
    
    border-radius: 4px 4px 0px 0px !important;
    text-transform: capitalize !important;
}
.selected-tab-happy-hours {
    background-color:  #712220 !important;
    color: #fff !important;
}
.schedule-item{
    font-size: 1.111vw !important;
    font-weight: 500 !important;
    
}
.day-time-typo{
    display: flex;
    width: 16.806vw;
    
    justify-content: space-between;
    margin-bottom: 0.167vw !important;
}
.cancel-popup-catalogue{
    width: 2.014vw;
}
.cancel-popup-catalogue-event{
    width: 2.014vw;
}
.pop-up-dialog-catalogue .MuiPaper-rounded{
    border-radius: 8px !important;
}
.pop-up-dialog-amenities-catalogue .MuiPaper-rounded{
    border-radius: 8px !important;
    width: 53.403vw;
    max-width: 53.403vw;
}
.pop-up-dialog-action-catalogue{
    padding: 0 !important;
}
.pop-up-btn-catalogue{
    padding: 0 !important;
    min-width: fit-content !important;
    justify-content: flex-end !important;
}
.pop-up-dialog-content-catalogue{
    padding: 2.431vw 7.986vw 4.514vw 4.444vw !important;
}
.pop3-up-dialog-catalogue .MuiPaper-rounded{
    border-radius: 0.556vw !important;
    width: 41.389vw !important;
}
.pop-up-dialog-content-text-catalogue{
    display: flex;
    color: #712220 !important;
}
.pop-up2-dialog-title-catalogue{
    padding: 0.903vw 4.444vw 1.667vw 4.444vw !important;
    font-size: 1.667vw !important;
    font-weight: 600 !important;
    
}
.pop-up2-dialog-content-catalogue{
    padding: 0px 8.436vw 4.514vw 4.444vw !important;
}
.pop-up3-dialog-content-catalogue{
    padding: 0px 4.444vw 1.111vw 4.444vw !important;
}
.pop-up2-dialog-content-text-catalogue{
    display: flex;
    justify-content: space-between;
    width: 21.356vw !important;
}
.pop-up3-dialog-content-text-catalogue{
    display: flex;
    flex-direction: column;
}
.share-popup-icon{
    height: 3.889vw;
}
.text-area-style-catalogue-main{
    color:#712220 !important;
    border-radius: 5px;
    
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #712220 !important;
    border: 1px solid #712220 !important ;
}
 .flag-dropdown{
    text-transform: none !important;
    padding: 0 !important;
    font-weight: 400 !important;
    color: #712220 !important;
    font-size: 1.111vw !important;
    margin-bottom: 1.667vw !important;
    
}
.menuitem-list-catalogue{
    
    font-size: 1.111vw !important;
    color: #712220 !important;
    padding: 0.694vw 0px 0.556vw 1.25vw !important;
}
.menu-wrapper-catalogue{
    margin-top: 2.222vw;
}
.menu-img-btn-wrapper{
    border: 1px solid #712220 !important;
    border-radius: 8px;
    text-align: center;
    padding: 1.875vw 0.764vw 1.25vw 0.764vw;
    margin: 1.111vw 0;
}
.menu-img-catalogue{
    height: 21.181vw;
}
.btnStyleMenuPdf {
    background-color: #70211F !important;
    font-size: 0.972vw !important;
    height: 1.667vw !important;
    border-radius: 4px !important;
    color: #fff !important;
    text-transform: none !important;
    padding: 0.347vw 1.111vw 0.347vw 1.111vw !important;
    font-weight: 600 !important;
    margin-top: 0.556vw !important;
  }

  .pop-up3-dialog-action-catalogue{
    padding: 0 4.444vw 2.917vw 0px;
  }

  .btnSubmitCatalogue {
    background-color: #70211F !important;
    
    font-size: 1.111vw !important;
    height: 3.889vw !important;
    width: 11.528vw !important;
    border-radius: 8px !important;
    color: #fff !important;
    text-transform: none !important;
    padding: 0px !important;
    font-weight: 600 !important;
  }

  .alert-submission-login{
    background-color: rgb(227, 57, 57) !important;
    color: #fff !important;
    align-items: center;
  }

  .share-btn-icon-catalogue{
    width: 1.667vw;
  }
  .share-btn-icon-catalogue-event{
    width: 1.667vw;
  }
  .cal-btn-icon-catalogue{
    width: 1.736vw;
  }
  .fitericon-span-catalogue{
    font-size: 0.496vw;
    color: #712220;
    font-family: "Museo Sans", sans-serif !important;
  }
  .pop-up2-dialog-share-catalogue{
    padding: 0 4.444vw 0vw 4.444vw !important;
    text-align: center ;
    font-size: 1.267vw !important;
    font-weight:500 !important;
    
}
.custom-calendar .MuiPickersDay-root {
    width: 5.6vw;
    height: 5.2776vw;
    min-width: 5.6vw;
}
.pop-up-dialog-content-event{
    padding: 0.947vw 4.861vw 4.861vw 4.861vw !important;
}
.custom-calendar .MuiPickersCalendarHeader-label {
    font-size: 1.3336vw;  
    font-weight: 600; 
    min-width: max-content;
}
.custom-calendar .MuiPickersSlideTransition-root{
    min-height: 32.1336vw;
}

.pop-up-dialog-event-catalogue .MuiPaper-rounded{
    border-radius: 8px !important;
    width: 79.7776vw;
    max-width: 79.7776vw;
    height: 54.922vw;
}

.close-menu-button {
    position: absolute !important;
    right: 0.556vh !important;
    padding: 0 !important;
    top: 0.556vh !important;
  }
  