.backGround1 {   
    width: 100% !important;
}

.preview{
    color: #181818;
    font-weight: 700 !important;
    font-size: 2.5vw !important;
    height: 3.333vw !important;
    margin-bottom: 1.389vw !important;
}
.appFeatures{
    color: #181818;
    font-weight: 600 !important;
    width: 27.847vw;
    height: 1.806vw;
    font-style: italic;
    font-size: 1.389vw !important ;
    text-align: center;
    margin-top: 2px !important;
}

.headingNew {
    color: #181818;
    font-family: 'Museo Sans', sans-serif !important;
    font-weight: 600 !important;
    font-size: 2.5vw !important;
    width: 17.361vw;
    height: 3.194vw;
    text-align: start;
    margin-bottom: 2.569vw !important;
    align-self: flex-start;
}

.box1 {
    display: flex;
    flex-direction: column;
    gap:19.653vw;
    margin-top: 8.403vw;
    margin-left: 10.764vw;
}

.box2 {
    display: flex;
    flex-direction: column;
    gap:6.528vw;
}



.nameEmailCnt {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.successfulMsg{
    font-size: 1.111vw !important;
   
    font-weight: 400 !important;
    align-items: center !important;
    padding: 0px 16px !important;
}

.contactUsTxt{
    color: #181818;
    font-weight: 700 !important;
    font-size: 2.5vw !important;
    width: 15.625vw;
    height: 3.194vw;
}


.inputFields{
    width: 22.5vw;
    height: 3.125vw;
    padding: 0.903vw 1.111vw;
    border: 1px solid #712220;
    border-radius: 5px;
    font-size: 1.111vw;
    font-family: "Museo Sans", sans-serif !important;
}

#messageTextArea {
    width: 47.083vw;
    height: 12.014vw;
    border: 1px solid #712220;
    border-radius: 5px;
    padding: 0.903vw 1.111vw;
    resize: none;
    font-size: 1.111vw;
    font-family: "Museo Sans", sans-serif !important;
}

.contactUsTxt::placeholder, .inputFields::placeholder, #messageTextArea::placeholder{
    color:#712220;
}
.contactUsTxt:focus, .inputFields:focus, #messageTextArea:focus{
    outline: none;
}


.submitBtn{
    width: 8.542vw !important;
    height: 2.778vw !important;
    border-radius: 8px !important;
    color:#FFF !important;
    background-color: #712220 !important;
    font-size: 0.972vw !important;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    align-self: flex-end;
    text-transform: none !important;
}

.valid-email-error{
    color: #712220;
    font-size: 12px;
    align-self: flex-end;
    margin-top: -8px;
}

.groupImg1{
    width: 100%;
}
.imgBox{
    width: 100%;
}

.blog-card-img-landing{
    height: 20.833vw;
    width: 25.014vw !important;
}
.blog-img-content-landing{
    padding: 0.417vw 0.833vw;
    margin-top: -2.153vw;
    align-items: center;
    width: fit-content !important;
    background-color: #9C0E2E !important;
  }

  .typo-heading-blog-landing{
    font-weight: 700 !important;
    font-size: 1.528vw !important;
    margin-top: 1.389vw !important;
  }

  .typo-description-blog p{
    margin: 0 !important;
  }

  .blog-content-font-landing{
    font-size: 0.764vw !important;
    margin-top: 1.667vw !important;
    letter-spacing: 0.139vw !important;
  }